import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import { detailsStrutturaOrganizzativa, loadDipartimenti, loadDipartimentiStorico, detailsStrutturaOrganizzativaStorico, setDataStorico} from '../../store/organigrammaSlice';
import CustomBreadcrumb from "../../components/common/custom-breadcrumb/CustomBreadcrumb";
import { DipendenteRegionaleDto, DipendenteRegionaleDtoCategoriaEnum, StrutturaOrganizzativaDtoTipoEnum, StrutturaOrganizzativaOrganigrammaDto } from '../../services/ms-anagrafica-unica';
import OrganigrammaTree from '../../components/organigramma-tree/OrganigrammaTree';
import { useSearchParams } from "react-router-dom";
import './style.scss'
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ORGANIGRAMMA, HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import { Icon } from 'design-react-kit';
import { formatDate } from 'utility/formatDate';
import { showFieldIfNotNull } from 'utility/utility';

const DettaglioStrutturaOrganizzativa = () => {
    const { idStrutturaOrganizzativa } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const so = searchParams.get('so')
    const tipo = searchParams.get('tipo')

    const [mostraTitoloStorico, setMostraTitoloStorico] = useState('');

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { elencoDipartimentiResult, dipartimentoResult, breadcrumb, strutturaOrganizzativaAttiva, dipartimentoSelezionato, 
            dataStorico, organigrammaStorico, dipartimentoStoricoResult, elencoDipartimentiStoricoResult, fromOrganigrammaPage} = useAppSelector(
        (state) => state.organigramma
    );

    const isoDateFineGiornata = () => {
        var data = new Date(dataStorico!);
        data.setUTCHours(23)
        data.setMinutes(59)
        data.setSeconds(59)
        setDataStorico(data)

        return data.toISOString()
    }

    const scrollToContent = () => {
        document && document.getElementById('dettaglio-struttura')?.scrollIntoView();
    }

    const apriDettaglioStrutturaOrganizzativa = (strutturaOrganizzativa: StrutturaOrganizzativaOrganigrammaDto) => {
        if(!organigrammaStorico) {
            if (strutturaOrganizzativa.id && strutturaOrganizzativa.tipo) {
                setSearchParams({
                    so: strutturaOrganizzativa.id,
                    tipo: strutturaOrganizzativa.tipo
                }, { replace: true });
                dispatch(detailsStrutturaOrganizzativa({
                    idStrutturaOrganizzativa: strutturaOrganizzativa.id,
                    setAttiva: true
                })).unwrap().then( resp => !!resp &&  scrollToContent())
            }       
        }
        else {
            if (strutturaOrganizzativa.id && strutturaOrganizzativa.tipo) {
                setSearchParams({
                    so: strutturaOrganizzativa.id,
                    tipo: strutturaOrganizzativa.tipo
                }, { replace: true });
                dispatch(detailsStrutturaOrganizzativaStorico({
                    idStrutturaOrganizzativa: strutturaOrganizzativa.id,
                    setAttiva: true,
                    dataInput: isoDateFineGiornata()
                })).unwrap().then( resp => !!resp &&  scrollToContent())
            }
        }

    }

    useEffect(() => {
        if(organigrammaStorico && dataStorico) {
            if (!!elencoDipartimentiStoricoResult) {
                dispatch(loadDipartimentiStorico(isoDateFineGiornata()))
            }
            dispatch(detailsStrutturaOrganizzativaStorico({
                idStrutturaOrganizzativa: idStrutturaOrganizzativa!,
                setAttiva: true,
                dataInput: isoDateFineGiornata()
            }))

             setMostraTitoloStorico(titoloStorico())
        }else{
            if (!!elencoDipartimentiResult) {
                dispatch(loadDipartimenti())
            }
           
            if(!dipartimentoResult || dipartimentoResult.id !== idStrutturaOrganizzativa){
                dispatch(detailsStrutturaOrganizzativa({
                    idStrutturaOrganizzativa: idStrutturaOrganizzativa!,
                    setAttiva: !!!so,
                }))      
            }
            if(!!so){
                dispatch(detailsStrutturaOrganizzativa({
                    idStrutturaOrganizzativa: so,
                    setAttiva: true,
                }))
            }
        }
    }, []);

    const titoloStorico = () => {
      return organigrammaStorico && dataStorico? ' al ' + formatDate(dataStorico.toLocaleString(), false, '-') : ''
    }

    const verificaEsistenzaRisultati = () => {
        if(!organigrammaStorico) {
            return dipartimentoResult && Object.keys(dipartimentoResult).length > 0 
        }
        else {
            return dipartimentoStoricoResult && Object.keys(dipartimentoStoricoResult).length > 0 
        }
    }

    const scegliDipartimentoResult = () => {
        if(!organigrammaStorico) {

            if(so == null && tipo == null){
                //Visualizza da una Struttura Organizzativa
                const newDipartimentoResult = {};

                for (let [key, value] of Object.entries(dipartimentoResult!)) {
                    if(key === 'struttureOrganizzativeFiglie') {
                        value = JSON.parse(JSON.stringify(dipartimentoResult!.struttureOrganizzativeFiglie?.filter(elem => elem.stato === 'CENSITA')))
                    }
                    Object.assign(newDipartimentoResult, {[key] : value});
                }
                return newDipartimentoResult!
            } else {
                return dipartimentoResult!
            }
        }
        else {
            return dipartimentoStoricoResult!

        }
    }

    const decodificaTipoIncarico = (dip: DipendenteRegionaleDto) =>  {
        let idDecodifica;
        
        dip.elencoAssegnazioni?.forEach(ass=> {
            if (ass.idIncarico === strutturaOrganizzativaAttiva?.id)
                idDecodifica=ass.idDecodifica
        })

        return idDecodifica
    }

    const defineBack = () => {
        if(fromOrganigrammaPage)
            return HREF_ORGANIGRAMMA
        else 
            return HREF_ELENCO_STRUTTURE_ORGANIZZATIVE;
    }

    const getListaDipendenti = () => {
        let lista = strutturaOrganizzativaAttiva?.dipendentiRegionali?.filter(dipendente => 
            decodificaTipoIncarico(dipendente) === "5"
       )
       return (lista && lista?.length > 0) ? lista : [];
    }
    const getListaNonDipendenti = () => {
        let lista = strutturaOrganizzativaAttiva?.dipendentiRegionali?.filter(dipendente => 
             decodificaTipoIncarico(dipendente) !== "5"
        )
        return (lista && lista?.length > 0) ? lista : [];
    }

    return (
        <>
            <div className="organigramma-dettaglio-so">
                {verificaEsistenzaRisultati() && strutturaOrganizzativaAttiva && strutturaOrganizzativaAttiva.id && idStrutturaOrganizzativa && (
                    <>
                        <div className="mb-5">
                        <PageHeader showIcon={true} urlBack={defineBack()} state={{form: true}} title={strutturaOrganizzativaAttiva.descrizioneBreve! + mostraTitoloStorico}></PageHeader>
                            <CustomBreadcrumb items={breadcrumb} openSo={apriDettaglioStrutturaOrganizzativa} />
                            <OrganigrammaTree
                                organigramma={scegliDipartimentoResult()}
                                active={strutturaOrganizzativaAttiva}
                                visualizzazione={tipo === StrutturaOrganizzativaDtoTipoEnum.Servizio}
                                openSo={apriDettaglioStrutturaOrganizzativa} />
                        </div>

                        <section className="mb-3">
                            <div className='row col-12'>
                                <div className='sintesi col-12 col-lg-8 border-bottom'>
                                    {strutturaOrganizzativaAttiva.descrizione ? <>
                                        <div className='border-bottom mb-3 d-flex flex-wrap'>
                                            <div className='info-so-item col-lg-4'>
                                                <div>{t('data inizio validita')}: <span>{formatDate(strutturaOrganizzativaAttiva.dataInizioValidita, false, '-')}</span></div>
                                            </div>
                                        </div>
                                        </> : <div className='border-bottom mt-4'></div>}

                                    <span className='d-flex align-items-center mb-3' style={{fontWeight: '600'}}><Icon color="primary" icon="it-info-circle" className='mr-2' />IN SINTESI</span>
                                    <div className='so-descrizione'> {strutturaOrganizzativaAttiva.descrizione}</div>
                                </div>
                               <div className='contatti col-12 col-lg-4'>
                                    <div className="info-box-contatti">
                                        <h6 className="mb-3 text-uppercase"><Icon color="primary" icon="it-mail"/> {t("contatti di struttura")}</h6>
                                        <dl>
                                            <dt className='col-sm-12'>{t("email")}</dt>
                                            <dd className='col-sm-12'>{!!strutturaOrganizzativaAttiva.email1 ? 
                                                <a href={`mailto:${strutturaOrganizzativaAttiva.email1}`}>{strutturaOrganizzativaAttiva.email1}</a> : '-'}
                                            </dd>

                                            <dt className='col-sm-12'>{t("email_2")}</dt>
                                            <dd className='col-sm-12'>{!!strutturaOrganizzativaAttiva.email2 ? 
                                                <a href={`mailto:${strutturaOrganizzativaAttiva.email2}`}>{strutturaOrganizzativaAttiva.email2}</a> : '-'}
                                            </dd>

                                            <dt className='col-sm-12'>{t("pec")}</dt>
                                            <dd className='col-sm-12'>{!!strutturaOrganizzativaAttiva.pec1 ? 
                                                <a href={`mailto:${strutturaOrganizzativaAttiva.pec1}`}>{strutturaOrganizzativaAttiva.pec1}</a> : '-'}</dd>

                                            <dt className='col-sm-12'>{t("pec_2")}</dt>
                                            <dd className='col-sm-12'>{!!strutturaOrganizzativaAttiva.pec2 ? 
                                                <a href={`mailto:${strutturaOrganizzativaAttiva.pec2}`}>{strutturaOrganizzativaAttiva.pec2}</a> : '-'}
                                            </dd>

                                            <dt className='col-sm-12'>{t("telefono")}</dt>
                                            <dd className='col-sm-12'>{!!strutturaOrganizzativaAttiva.telefono1 ? 
                                                <a href={`tel:${strutturaOrganizzativaAttiva.telefono1}`}>{strutturaOrganizzativaAttiva.telefono1}</a> : '-'}</dd>

                                            <dt className='col-sm-12'>{t("telefono_2")}</dt>
                                            <dd className='col-sm-12'>{!!strutturaOrganizzativaAttiva.telefono2 ? 
                                                <a href={`tel:${strutturaOrganizzativaAttiva.telefono2}`}>{strutturaOrganizzativaAttiva.telefono2}</a> : '-'}
                                            </dd>

                                            <dt className='col-sm-12'>{t("indirizzo")}</dt>
                                            <dd className='col-sm-12'>{showFieldIfNotNull(strutturaOrganizzativaAttiva.indirizzo)}</dd>
                                            <dt className='col-sm-12'>{t("citta")}</dt>
                                            <dd className='col-sm-12'>{showFieldIfNotNull(strutturaOrganizzativaAttiva.cap) + ' ' + showFieldIfNotNull(strutturaOrganizzativaAttiva.citta, '')}</dd>
                                        </dl>
                                    </div>
                               </div>
                            </div>
                        </section>

                        <div className='row col-lg-12 mb-5'>
                        {strutturaOrganizzativaAttiva.dipendentiRegionali && strutturaOrganizzativaAttiva.dipendentiRegionali.length > 0 && (
                            <div className="elenco-dip col-lg-6 mt-3">
                                <h3 className="mb-4">Elenco del personale</h3>

                                {getListaNonDipendenti().map((dipendente, i) => (
                                    <div className="row col-12 mb-2 flex-column flex-lg-row">
                                        <div className='col-sm-4 font-bold uppercase'>{t(`incaricoDipendente.${decodificaTipoIncarico(dipendente)}`)}</div>
                                        <div className='col-sm-8'>
                                                <div key={i}>
                                                    {dipendente.categoria === DipendenteRegionaleDtoCategoriaEnum.Cd
                                                        || dipendente.categoria === DipendenteRegionaleDtoCategoriaEnum.Sz
                                                        || DipendenteRegionaleDtoCategoriaEnum.Sr ?
                                                        <span key={i}>{dipendente.cognome} {dipendente.nome}</span>
                                                        :
                                                        <span key={i}>{dipendente.cognome} {dipendente.nome} (Categoria {dipendente.categoria})</span>
                                                    }
                                                </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="row col-12 flex-column flex-lg-row">
                                    <div className='col-sm-4 font-bold uppercase'>{t(`incaricoDipendente.5`)}</div>
                                    <div className='col-sm-8'>
                                        {getListaDipendenti().map((dipendente, i) => (
                                            <div key={i}>
                                                {dipendente.categoria === DipendenteRegionaleDtoCategoriaEnum.Cd
                                                    || dipendente.categoria === DipendenteRegionaleDtoCategoriaEnum.Sz
                                                    || DipendenteRegionaleDtoCategoriaEnum.Sr ?
                                                    <span key={i}>{dipendente.cognome} {dipendente.nome}</span>
                                                    :
                                                    <span key={i}>{dipendente.cognome} {dipendente.nome} (Categoria {dipendente.categoria})</span>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {strutturaOrganizzativaAttiva.posizioniOrganizzative && strutturaOrganizzativaAttiva.posizioniOrganizzative.length > 0 && (
                            <div className="col-lg-6 mt-3">
                                <h3 className="mb-4">Elevate Qualificazioni</h3>
                                {strutturaOrganizzativaAttiva.posizioniOrganizzative.map((posizione, i) => (
                                    <dl className='row' key={i}>
                                        <dt className='col-sm-6'>{posizione.descrizioneBreve}</dt>
                                        <dd className='col-sm-6 pl-2'>{posizione.responsabile} {posizione.dipendenteRegionale}</dd>
                                    </dl>
                                ))}
                            </div>
                        )}
                    </div>

                    </>
                )}
            </div>
        </>
    );
}

export default DettaglioStrutturaOrganizzativa;