import { useEffect, useRef, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { StrutturaOrganizzativaDtoTipoEnum, StrutturaOrganizzativaOrganigrammaDto } from "../../services/ms-anagrafica-unica";
import CustomTreeNode from "./CustomTreeNode";
import { useTranslation } from "react-i18next";
import './style.scss';

interface OrganigrammaTreeProps {
    organigramma: StrutturaOrganizzativaOrganigrammaDto,
    active: StrutturaOrganizzativaOrganigrammaDto,
    visualizzazione: boolean,
    openSo: Function
}

const OrganigrammaTree = (props: OrganigrammaTreeProps) => {
    const [o,setO] = useState<StrutturaOrganizzativaOrganigrammaDto>(props.organigramma)
    const [dipartimentoAttivo, setDipartimentoAttivo] = useState<boolean>(true);
    const [visualizzazioneCompatta, setVisualizzazioneCompatta] = useState<boolean>(!props.visualizzazione);
    const { t } = useTranslation();
    const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

    const centraLaMappa = (rif:string) => {
        if (transformComponentRef.current) {
          const { zoomToElement } = transformComponentRef.current;
          rif === 'dipartimento' && zoomToElement("dipartimento",1);
          rif === 'active' && zoomToElement("active",1);
        }
    };

    const openSoDetail = (soDestinazione: StrutturaOrganizzativaOrganigrammaDto) => {
        soDestinazione.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento ? setDipartimentoAttivo(true) : setDipartimentoAttivo(false)
        props.openSo(soDestinazione)
    }

    const chiudiGrafico = () => {
        setVisualizzazioneCompatta(true)
        if (props.active.tipo === StrutturaOrganizzativaDtoTipoEnum.Servizio) {
            openSoDetail(
                {
                    id: props.active.codiceIncaricoPadre,
                    descrizioneBreve: props.active.descrizioneBrevePadre,
                    tipo: StrutturaOrganizzativaDtoTipoEnum.Sezione
                }
            )
        }
    }

    useEffect( () => {        
        if (visualizzazioneCompatta) {
            let ridotto:StrutturaOrganizzativaOrganigrammaDto = {};
            ridotto = {...props.organigramma};
            const sezioni:Array<StrutturaOrganizzativaOrganigrammaDto> = [];
            if (ridotto.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento) {
                ridotto.struttureOrganizzativeFiglie && ridotto.struttureOrganizzativeFiglie.forEach((element,index) => {
                    sezioni.push({
                        ...element,
                    struttureOrganizzativeFiglie: []});
                });
                ridotto.struttureOrganizzativeFiglie = sezioni;
            }
            setO(ridotto)
        } else {
            setO(props.organigramma)
        }
        centraLaMappa('active');
    }, [props, visualizzazioneCompatta]);

    // useEffect(()=> {
    //     if (o) {
    //         (props.active.tipo === StrutturaOrganizzativaDtoTipoEnum.Servizio) && 
    //            openSoDetail(
    //             {
    //                 id: props.active.codiceIncaricoPadre,
    //                 descrizioneBreve: props.active.descrizioneBrevePadre,
    //                 tipo: StrutturaOrganizzativaDtoTipoEnum.Sezione
    //             }
    //         )
    //         centraLaMappa('active')
    //     }
    // },[o])

    return (
        <section>
            <div className="row">
                <div className="col">
                    <div className="organigramma-tree border border-dark pb-3">
                        <TransformWrapper ref={transformComponentRef}>
                            {() => (
                            <TransformComponent>
                                {o && o.id && o.descrizioneBreve && o.tipo && (
                                <Tree
                                    label={<CustomTreeNode active={props.active.id === o.id} struttura={o} cambiaSoAttiva={() => openSoDetail(o)} />}
                                    lineWidth={'1px'}
                                    lineColor={'#06c'}
                                    lineBorderRadius={'10px'}
                                >
                                    {o.struttureOrganizzativeFiglie && o.struttureOrganizzativeFiglie.length > 0 && o.struttureOrganizzativeFiglie.map( so => {
                                        if (so.struttureOrganizzativeFiglie && so.struttureOrganizzativeFiglie.length > 0) {
                                            return ( so.id && so.descrizioneBreve && so.tipo && (
                                                <TreeNode
                                                    label={<CustomTreeNode struttura={so} active={props.active.id === so.id} cambiaSoAttiva={() => so.id && openSoDetail(so)} />} 
                                                    key={so.id}
                                                >
                                                    {so.struttureOrganizzativeFiglie.map( soFiglia => ( soFiglia.id && soFiglia.descrizioneBreve && soFiglia.tipo && (
                                                    <TreeNode label={<CustomTreeNode struttura={soFiglia} active={props.active.id === soFiglia.id} cambiaSoAttiva={() => soFiglia.id && openSoDetail(soFiglia)} />} key={soFiglia.id} />
                                                    )))}
                                                </TreeNode>
                                            ))
                                        } else {
                                            return (
                                                <TreeNode label={<CustomTreeNode struttura={so} active={props.active.id === so.id} cambiaSoAttiva={() => so.id && openSoDetail(so)} />} key={so.id} />
                                            )
                                        }
                                    })}
                                </Tree>
                                )}
                            </TransformComponent>
                            )}
                        </TransformWrapper>
                        <>{visualizzazioneCompatta ?
                            <span className="controls" onClick={() => setVisualizzazioneCompatta(false)}>Vedi organigramma completo</span>:
                            <span className="controls" onClick={chiudiGrafico}>Vedi organigramma compatto</span>
                        }
                        {dipartimentoAttivo ? (
                            <span className="controls" onClick={() => centraLaMappa('active')}>Centra all'unità organizzativa</span>
                        ) : (
                            <>
                                <span className="controls" onClick={() => centraLaMappa('dipartimento')}>Centra all'unità organizzativa</span>
                                <span className="controls" onClick={() => centraLaMappa('active')}>Centra alla struttura attiva</span>
                            </>

                        )}
                        </>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OrganigrammaTree;