import CustomAutocomplete from "components/common/custom-autocomplete/CustomAutocomplete";
import TableTheadCustom from "components/common/custom-table/table-thead-custom";
import { format, parseISO } from 'date-fns';
import { Accordion, AccordionBody, AccordionHeader, Alert, Button, Col, DropdownMenu, DropdownToggle, Icon, Input, LinkList, LinkListItem, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledDropdown } from "design-react-kit";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { checkCampiRicercaValorizzati } from "utility/utility";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CustomSelect, { CustomSelectItem } from "../../components/common/custom-select/CustomSelect";
import PageHeader from "../../components/common/page-header/PageHeader";
import Pagination from "../../components/common/pagination/Pagination";
import {
    // HREF_ASSEGNAZIONE_CODICE_CIFRA,
    HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA,
    // HREF_ASSEGNAZIONE_UTENZA_CIFRA,
    HREF_BOZZA_STRUTTURA_ORGANIZZATIVA,
    HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA,
    HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA,
    HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA,
    HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA,
    HREF_NUOVA_STRUTTURA_ORGANIZZATIVA
} from "../../components/layout/sidemenu/sidemenuConstants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { OptionDtoStatoStrutturaOrganizzativaValoreEnum, OptionDtoStatoStrutturaOrganizzativaValueEnum, OptionDtoTipoStrutturaOrganizzativaValueEnum, RuoloUtenteAutenticatoDto, StrutturaOrganizzativaControllerApi, StrutturaOrganizzativaDto, StrutturaOrganizzativaDtoTipoEnum } from "../../services/ms-anagrafica-unica";
import { setFromOrganigrammaPage, setOrganigrammaStorico } from "../../store/organigrammaSlice";
import { MS_AU_CONFIG,STATUS_FULLFILLED } from "../../store/store-constants";
import {
    deleteStruttureOrganizzative,
    inoltraStruttureOrganizzative,
    loadSOFormSearch,
    patchInoltraSo,
    resetInputForm,
    resetInsertResult,
    resetUpdateForm,
    searchStruttureOrganizzative,
} from "../../store/strutturaOrganizzativaSlice";

const schema = z.object({
    denominazione: z.string().max(50, { message: "lenghtdenominazione" }).optional(),
    tipo: z.string().optional()
});

const initialValues = {
    codInca: "",
    denominazione: "",
    tipo: "",
    comune: "",
    pageNum: 0,
    pageSize: 10,
    sort: "",
    direction: ""
};

type Direction = 'ASC' | 'DESC';

interface SortingState {
    direction: Direction;
}

function ElencoStruttureOrganizzative(props: {operations: RuoloUtenteAutenticatoDto}) {
    const searchFormElencoSO = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: () => doSearch()
    });

    const handleCittaSelect = (value: string) => {
        searchFormElencoSO.setFieldValue('citta', value);
        searchFormElencoSO.handleChange(value);
    }

    const autocompleteStruttureOrganizzative = debounce(async (inputValue: string, callback: any) => {
        const api = new StrutturaOrganizzativaControllerApi(MS_AU_CONFIG);
        let options: { value: string | undefined; label: string | undefined; }[] = []
        let response;
 
        if (!inputValue) {
            response = await api.searchFirstTenComuniStrutturaOrganizzativa();
        } else {
            response = await api.searchComuniStrutturaOrganizzativa({ nomeComune: inputValue });
        }
        
        response.data?.forEach(c => {
            options.push({ value: c, label: c });
        });
        callback(options);
    }, 500)

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location =  useLocation();
    const { searchResult, searchStatus, formSearchComponents, searchInput } = useAppSelector((state) => state.strutturaOrganizzativa);
    const [formCollapseOpen, setFormCollapseOpen] = useState(false);

    useEffect(() => {
        dispatch(resetUpdateForm());
        dispatch(resetInputForm())
        dispatch(resetInsertResult())
        dispatch(loadSOFormSearch());
        dispatch(setOrganigrammaStorico(false))

        if (location.state?.form && !!searchInput) {
            if(checkCampiRicercaValorizzati(searchInput))
                setFormCollapseOpen(true);
            dispatch(searchStruttureOrganizzative(searchInput))
        } else {
            doSearch();
        }
    }, [])


    let hasResults = searchResult && searchResult.totalElements ? searchResult.totalElements > 0 : false;

    const handleChange = (name: string, selectedOption: any) => {
        searchFormElencoSO.setFieldValue(name, selectedOption);
    };

    useEffect(() => {
        searchFormElencoSO.setValues({
            codInca: '',
            denominazione: searchInput?.denominazione || '',
            tipo: searchInput?.tipo || '',
            comune: searchInput?.comune || '',
            pageNum: searchInput?.pageNum || 0,
            pageSize: searchInput?.pageSize || 10,
            sort: searchInput?.sort || '',
            direction: searchInput?.direction || '',
        })
    }, [searchInput])

    const [sorting, setSorting] = useState<SortingState>({ direction: 'DESC' });

    let tipoStrutturaOptions: CustomSelectItem[] = [];
    formSearchComponents?.tipo?.forEach((s) => {
        tipoStrutturaOptions.push({ label: s.label, value: s.valore });
    });

    const doSearch = () => {
        dispatch(searchStruttureOrganizzative(searchFormElencoSO.values));
    };

    const handlePageChange = (pageNum: number) => {
        searchFormElencoSO.setFieldValue("pageNum", pageNum - 1);
        searchFormElencoSO.handleSubmit();
    };

    const searchStartKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            searchFormElencoSO.setFieldValue("pageNum", 0);
            searchFormElencoSO.handleSubmit();
        }
    }

    const ordinamentoElencoSO = (columnName: string) => {
        let direction: Direction;
        direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
        setSorting({ direction });
        searchFormElencoSO.setFieldValue("sort", columnName);
        searchFormElencoSO.setFieldValue("direction", sorting.direction);
        searchFormElencoSO.handleSubmit();
    };
    
    const [isOpenModalInoltraSo, toggleModalInoltraSo] = useState(false);
    const [isOpenModalEliminaSo, toggleModalEliminaSo] = useState(false);
    const [strutturaSelezionata, setStrutturaSelezionata] = useState<{id: string, name: string}>()

    // const inoltra = (id: string) => {
    //     setStrutturaSelezionata(id)
    //     toggleModalInoltraSo(!isOpenModalInoltraSo)
    // }

    const elimina = (id: string, name: string) => {
        setStrutturaSelezionata({id, name})
        toggleModalEliminaSo(!isOpenModalEliminaSo)
    }

    const confermoInoltro = () => {
        const body: patchInoltraSo = {
            idStrutturaOrganizzativa: strutturaSelezionata!.id,
            bodyRequest: { requestType: 'Inoltra' }
        }

        setStrutturaSelezionata(undefined)
        dispatch(inoltraStruttureOrganizzative(body)).unwrap().then(resp => !!resp && window.location.reload());
    }

    const confermoElimina = () => {
        setStrutturaSelezionata(undefined)
        dispatch(deleteStruttureOrganizzative(strutturaSelezionata?.id!))
            .unwrap().then(resp => !!resp && window.location.reload());
    }

    const showModificaSOOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.some(funzionalita => ['SO_MOD_DIPA',
                'SO_MOD_SEZ',
                'SO_MOD_SERV',
                'SO_DISA_SERV',
                'SO_DISA_DIPA',
                'SO_DISA_SEZ',
                'SO_ASS_CODCIFRA',
                'SO_ASS_CODIPA',
                'SO_ASSOCIA_SERV',
                'SO_ASSOCIA_DIP',
                'SO_ASSOCIA_SEZ',
                'SO_CENSISCI',
                'SO_DISASSOCIAZIONE_SERV',
                'SO_DISASSOCIAZIONE_DIPA',
                'SO_DISASSOCIAZIONE_SEZ',
                'SO_DETTAGLIO'].includes(funzionalita))
        && (soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Compilata || 
            soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Censita || 
            soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata)

    const showAssegnazioneCodiceCifraOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum, soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('SO_ASS_CODCIFRA')
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata
        && soTipo !== OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio

    const showDisassocia = (soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SERV') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio)
        || (props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_DIPA') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Dipartimento)
        || (props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SEZ') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Sezione)

    const showAssocia = (soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SERV') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio)
        || (props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_DIP') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Dipartimento)
        || (props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SEZ') && soTipo === OptionDtoTipoStrutturaOrganizzativaValueEnum.Sezione)

    const showNuovaSo = () =>
        props.operations?.elencoFunzionalita?.includes('SO_INS_DIPA')
            || props.operations?.elencoFunzionalita?.includes('SO_INS_SEZ') 
            || props.operations?.elencoFunzionalita?.includes('SO_INS_SERV') 


    const showAssegnazioneCodiceIPAOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum) =>
        props.operations?.elencoFunzionalita?.includes('SO_ASS_CODIPA')
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata

    const showAssegnazioneCodiceBilancioOperations = (so: StrutturaOrganizzativaDto) => {
      return (props.operations?.elencoFunzionalita?.includes('SO_ASS_CODBILA') && so.stato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Registrata
        && (so.tipo === StrutturaOrganizzativaDtoTipoEnum.Dipartimento || so.tipo === StrutturaOrganizzativaDtoTipoEnum.Sezione))
    }

    const showDisattivaSOOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum) =>
        (props.operations?.elencoFunzionalita?.includes('SO_DISA_DIPA') 
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_SERV')
        || props.operations?.elencoFunzionalita?.includes('SO_DISA_SEZ'))
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Censita

    const showAssegnazioneUtenteCifraOperations = (soStato: OptionDtoStatoStrutturaOrganizzativaValueEnum, soTipo: OptionDtoTipoStrutturaOrganizzativaValueEnum) => 
        props.operations?.elencoFunzionalita?.includes('SO_INSERIMENTO_CIFRA')
        && soStato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Censita
        && soTipo !== OptionDtoTipoStrutturaOrganizzativaValueEnum.Servizio


    const showInoltra = (x: StrutturaOrganizzativaDto) => 
        props.operations?.elencoFunzionalita?.includes('SO_INOLTRA')
        && ((x.stato === 'REGISTRATA' && x.tipo === 'DIPARTIMENTO' && x.codiceDipartimentoRagioneria != null && x.codiceIpa != null) ||
            (x.stato === 'REGISTRATA' && x.tipo === 'SEZIONE' && x.codiceSezioneRagioneria != null && x.codiceIpa != null) ||
            (x.stato === 'REGISTRATA' && x.tipo === 'SERVIZIO' && x.codiceIpa != null))


    const showVisualizza = (x: StrutturaOrganizzativaDto) => 
        (x.stato !== 'COMPILATA' || (
            (x.stato === 'COMPILATA' && x.tipo === 'DIPARTIMENTO') || 
             x.stato === 'COMPILATA' && !!x.codiceIncaricoPadre)) 

    const showElimina = (x: StrutturaOrganizzativaDto) => 
        (props.operations?.elencoFunzionalita?.includes('SO_ELIMINAZIONE') && 
            x.stato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Compilata);

    const enableAzioni = (x: StrutturaOrganizzativaDto)=> showAssegnazioneCodiceBilancioOperations(x) || showAssegnazioneCodiceCifraOperations(x.stato!, x.tipo!) ||
                                                            showAssegnazioneCodiceIPAOperations(x.stato!) || showAssegnazioneUtenteCifraOperations(x.stato!, x.tipo!) ||
                                                             showAssocia(x.tipo!) || showDisassocia(x.tipo!) || showDisattivaSOOperations(x.stato!) || showModificaSOOperations(x.stato!) || 
                                                              showInoltra(x) || showVisualizza(x)

    const tastoNuovaSo = {
            buttonProperties: { outline: false },
            title: 'Nuova struttura organizzativa',
            buttonColor: 'primary',
            action: () => navigate(HREF_NUOVA_STRUTTURA_ORGANIZZATIVA),
            showButtonIcon: true,
            icon: 'it-plus',
            iconColor: 'white',
          };

    const bottoni = showNuovaSo()? [tastoNuovaSo] : [];
    
    const goToOrganigramma = (x: StrutturaOrganizzativaDto) => {

        let dipartimentoID;
        let soID;
        let tipo;

        switch (x.tipo) {
            case StrutturaOrganizzativaDtoTipoEnum.Servizio:
                dipartimentoID = x.strutturaOrganizzativaPadre?.codiceIncaricoPadre
                soID = x.id
                tipo = StrutturaOrganizzativaDtoTipoEnum.Servizio
                break;
            case StrutturaOrganizzativaDtoTipoEnum.Sezione:
                dipartimentoID = x.codiceIncaricoPadre
                soID = x.id
                tipo = StrutturaOrganizzativaDtoTipoEnum.Sezione
                break;
            default:
                dipartimentoID = x.id
                soID = x.id
                tipo = StrutturaOrganizzativaDtoTipoEnum.Dipartimento
                break;
        }
        dispatch(setFromOrganigrammaPage(false))
        navigate({
            pathname: `${HREF_DETTAGLIO_STRUTTURA_ORGANIZZATIVA}/${dipartimentoID}`,
            search: `so=${soID}&tipo=${tipo}`
        })
    }


    return (
        <div>
            <PageHeader showIcon={false} title={t("elenco strutture organizzative")!} buttons={bottoni}/>
            
            <Accordion className="form-custom form-bg filtri-ricerca" background="active">
                <AccordionHeader active={formCollapseOpen} onToggle={() => setFormCollapseOpen(!formCollapseOpen)}>
                    {t("filtri di ricerca")}
                </AccordionHeader>

                <AccordionBody active={formCollapseOpen}>
                    <div className="form-row mt-2">
                        <Input
                            type="hidden"
                            name="pageNum"
                            value={searchFormElencoSO.values.pageNum}
                            onChange={searchFormElencoSO.handleChange}
                        />
                        {/* <Input
                            type="text"
                            id="input-codice-1"
                            placeholder="Inserisci un codice"
                            wrapperClassName="col col-md-6"
                            name="codInca"
                            invalid={!!searchFormElencoSO.errors.codInca}
                            infoText={t(searchFormElencoSO.errors.codInca || "") || ""}
                            value={searchFormElencoSO.values.codInca}
                            onChange={searchFormElencoSO.handleChange}
                            onKeyUp={searchStartKeyPress}
                        /> */}

                        <Input
                            type="text"
                            id="input-denominazione-1"
                            placeholder="Inserisci una denominazione"
                            wrapperClassName="col col-md-6"
                            name="denominazione"
                            invalid={!!searchFormElencoSO.errors.denominazione}
                            infoText={t(searchFormElencoSO.errors.denominazione || "") || ""}
                            value={searchFormElencoSO.values.denominazione}
                            onChange={searchFormElencoSO.handleChange}
                            onKeyUp={searchStartKeyPress}
                        />
                        {/* <Input
                            type="text"
                            id="input-comune-1"
                            placeholder="Inserisci comune"
                            wrapperClassName="col col-md-6"
                            name="comune"
                            invalid={!!searchFormElencoSO.errors.comune}
                            infoText={t(searchFormElencoSO.errors.comune || "") || ""}
                            value={searchFormElencoSO.values.comune}
                            onChange={searchFormElencoSO.handleChange}
                            onKeyUp={searchStartKeyPress}
                        /> */}

                        <Col md="6">
                            <CustomAutocomplete
                                placeholder="Seleziona un comune"
                                id="select-comune"
                                value={searchFormElencoSO.values.comune}
                                loadOptionsFn={autocompleteStruttureOrganizzative}
                                handleOptionSelect={(e: string) => handleCittaSelect(e)}
                            />
                        </Col>

                        <Col md="6">
                            <CustomSelect
                                options={tipoStrutturaOptions}
                                value={searchFormElencoSO.values.tipo}
                                placeholder="Seleziona tipo"
                                invalid={!!searchFormElencoSO.errors.tipo}
                                infoText={t(searchFormElencoSO.errors.tipo || "") || ""}
                                onChange={(e) => handleChange("tipo", e)}
                                name="tipo"
                            />
                        </Col>

                    </div>

                    <div className="d-flex flex-row-reverse">
                        <Button
                            color="primary"
                            className="mt-2"
                            onClick={() => {
                                searchFormElencoSO.setFieldValue("pageNum", 0);
                                searchFormElencoSO.handleSubmit();
                            }}>
                            Applica
                        </Button>
                        <Button
                            outline
                            color="primary"
                            className="mt-2 mr-2"
                            onClick={searchFormElencoSO.handleReset}>
                            Pulisci
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>

            <div className="position-relative">
                {searchStatus === STATUS_FULLFILLED && searchResult.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
                {searchStatus === STATUS_FULLFILLED && hasResults && (
                    <>
                    <Table striped responsive size="sm" className={searchResult.totalPages! === 1 ? 'mb-5' : ''} style={{ marginTop: searchResult.totalElements! === 1 ? '80px' : '30px'}}>
                        <TableTheadCustom 
                            colonne={[
                                {titolo: 'denominazione', nomeColonna: 'denominazione', style:{ width: '20%'}},
                                {titolo: 'data inserimento', nomeColonna: 'dataInserimento'},
                                {titolo: 'data modifica', nomeColonna: 'dataModifica'},
                                {titolo: 'tipo struttura', nomeColonna: 'tipologiaStruttura'},
                                {titolo: 'comune', nomeColonna: 'citta'},
                                {titolo: 'stato', nomeColonna: 'codiceStato'},
                                {titolo: 'Azioni'}
                            ]} 
                            datiOrdinamento={searchFormElencoSO.values}
                            eseguiOrdinamento={ordinamentoElencoSO}/>

                        <tbody>
                            {(searchResult.data || []).map((x, i) => (
                                <tr key={x.id}>
                                    <td>{x.descrizioneBreve}</td>
                                    <td>{x.dataInserimento != null ? format(parseISO(x.dataInserimento), 'dd/MM/yyyy') : ''}</td>
                                    <td>{x.dataModifica != null ? format(parseISO(x.dataModifica), 'dd/MM/yyyy') : ''}</td>
                                    <td>{x.tipoStrutturaDescrizione}</td>
                                    <td>{x.citta ? x.citta?.toUpperCase() : "-"}</td>
                                    <td>{x.stato}</td>
                                    <td>
                                        <UncontrolledDropdown direction="left">
                                            <DropdownToggle disabled={!enableAzioni(x)} nav>
                                                <Icon icon="it-settings" color="primary" size="sm" />
                                            </DropdownToggle>

                                            <DropdownMenu className="no-arrow">
                                                <LinkList>
                                                    {showVisualizza(x) && 
                                                      <LinkListItem
                                                        size="medium"
                                                        onClick={() => goToOrganigramma(x)}>
                                                        <span>Visualizza</span>
                                                      </LinkListItem>}
                                                    
                                                    { showModificaSOOperations(x.stato!) && 
                                                      <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(
                                                                `${ x.stato === OptionDtoStatoStrutturaOrganizzativaValoreEnum.Compilata ? 
                                                                    HREF_BOZZA_STRUTTURA_ORGANIZZATIVA
                                                                    : 
                                                                    HREF_MODIFICA_STRUTTURA_ORGANIZZATIVA}/${x.id}`
                                                            )
                                                        }> <span>Modifica</span>
                                                      </LinkListItem>}

                                                    { showAssocia(x.tipo!) && x.stato === 'CENSITA' && 
                                                      <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(`${HREF_ASSEGNAZIONE_STRUTTURA_ORGANIZZATIVA}/${x.id}`)
                                                        }> <span>Associa</span>
                                                      </LinkListItem>}
                                                      
                                                    { showDisassocia(x.tipo!) && x.stato === 'CENSITA' && 
                                                      <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(`${HREF_DISASSOCIAZIONE_STRUTTURA_ORGANIZZATIVA}/${x.id}`)
                                                        }><span>Modifica Associazione</span>
                                                    </LinkListItem>}

                                                    { showDisattivaSOOperations(x.stato!) && 
                                                        <LinkListItem
                                                            size="medium"
                                                            onClick={() =>
                                                                navigate(`${HREF_DISATTIVAZIONE_STRUTTURA_ORGANIZZATIVA}/${x.id}`)
                                                        }><span>Disattivazione</span>
                                                    </LinkListItem>}

                                                    {/* { showAssegnazioneUtenteCifraOperations(x.stato!, x.tipo!) && 
                                                      <LinkListItem
                                                        size="medium"
                                                        onClick={() =>
                                                            navigate(`${HREF_ASSEGNAZIONE_UTENZA_CIFRA}/${x.id}`)
                                                        }
                                                        ><span>Utenza cifra</span>
                                                      </LinkListItem>} */}
                                                    
                                                    {/* { showInoltra(x) && 
                                                      <LinkListItem 
                                                        size="medium"
                                                        onClick={() => inoltra(x.id!)}
                                                        ><span>Inoltra</span>
                                                      </LinkListItem>} */}

                                                    { showElimina(x) && 
                                                      <LinkListItem
                                                        size="medium"
                                                        onClick={() => 
                                                            elimina(x.id!, x.descrizioneBreve!)
                                                        }> <span>Elimina</span>
                                                      </LinkListItem> }
                                                </LinkList>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div>
                        <Modal
                            isOpen={isOpenModalInoltraSo}
                            toggle={() => toggleModalInoltraSo(!isOpenModalInoltraSo)}
                            labelledBy='esempio1'
                            centered
                        >
                            <ModalHeader toggle={() => { toggleModalInoltraSo(!isOpenModalInoltraSo); setStrutturaSelezionata(undefined) }} id='esempio1'>
                                Inoltra struttura organizzativa
                            </ModalHeader>
                            <ModalBody>
                                <p>Sei sicuro di voler inoltrare questa struttura organizzativa?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='secondary' onClick={() => { toggleModalInoltraSo(!isOpenModalInoltraSo); setStrutturaSelezionata(undefined) }}>
                                    No
                                </Button>
                                <Button color='primary' onClick={() => { toggleModalInoltraSo(!isOpenModalInoltraSo); confermoInoltro() }}>
                                    Si
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Modal
                            isOpen={isOpenModalEliminaSo}
                            toggle={() => toggleModalEliminaSo(!isOpenModalEliminaSo)}
                            labelledBy='eliminaSo'
                            centered>

                            <ModalHeader toggle={() => { toggleModalEliminaSo(!isOpenModalEliminaSo); setStrutturaSelezionata(undefined) }} id='eliminaSo'>
                                Eliminazione Struttura Organizzativa
                            </ModalHeader>

                            <ModalBody>
                                <p>Sei sicuro di voler eliminare la struttura organizzativa <strong>{strutturaSelezionata?.name}</strong>?</p>
                            </ModalBody>

                            <ModalFooter>
                                <Button color='secondary' onClick={() => { toggleModalEliminaSo(!isOpenModalEliminaSo); setStrutturaSelezionata(undefined) }}>
                                    No
                                </Button>
                                <Button color='primary' onClick={() => { toggleModalEliminaSo(!isOpenModalEliminaSo); confermoElimina() }}>
                                    Si
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <Pagination
                        totalCount={searchResult.totalElements || 0}
                        siblingCount={1}
                        currentPage={searchFormElencoSO.values.pageNum + 1}
                        pageSize={searchResult.pageSize || 10}
                        onPageChange={(page: number) => handlePageChange(page) }
                        className="justify-content-center"
                    />
                </> 
                )}
            </div>
        </div>
    );
}

export default ElencoStruttureOrganizzative;
