import { Button, Input, TextArea } from 'design-react-kit';
import { FormikProps, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from "zod-formik-adapter";
import DocumentUpload from '../../components/common/document-upload/DocumentUpload';
import PageHeader from '../../components/common/page-header/PageHeader';
import { HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from '../../components/layout/sidemenu/sidemenuConstants';
import StruttureOrganizzativeDetailsForm from '../../components/strutture-organizzative-detail/StruttureOrganizzativeDetails';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RuoloUtenteAutenticatoDto, SOUpdateRequestDto } from '../../services/ms-anagrafica-unica';
import { STATUS_FULLFILLED } from '../../store/store-constants';
import { detailsStruttureOrganizzative, resetUpdateForm, updateStruttureOrganizzative } from '../../store/strutturaOrganizzativaSlice';
import { CAP, CITTA, DATA_INIZIO_VALIDITA, DECLARATORIA, DENOMINAZIONE, EMAIL, EMAIL_2, INDIRIZZO, PEC, PEC_2, TELEFONO, TELEFONO_2, TIPOLOGIA_STRUTTURA, UNITA_ORGANIZZATIVA } from './struttureOrganizzativeConstants';
import { regex } from 'utility/constants';


const schema = z.object({
    denominazione: z.string({required_error: 'required'}).max(500, 'lenghtdenominazione'),
    declaratoria: z.string({ required_error: 'required' }).max(5000, 'lenghtdeclaratoria'),
    email1: z.string({ required_error: 'required' }).regex(new RegExp(regex.email), 'emailNonCorretta').trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }),
    email2: z.string().regex(new RegExp(regex.email), 'emailNonCorretta').trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec1: z.string({ required_error: 'required' }).regex(new RegExp(regex.pec), 'pecNonCorretta').trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }),
    pec2: z.string().regex(new RegExp(regex.pec), 'pecNonCorretta').trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    telefono1: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    telefono2: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    indirizzo: z.string({ required_error: 'required' }).trim().min(1).max(100, 'lenghtIndirizzo'),
    citta: z.string({ required_error: 'required' }).trim().min(1).max(100, 'lenghtCitta'),
    cap: z.string({ required_error: 'required' }).trim().min(5, "lengthCap").max(5, "lengthCap"),
  }
  )

const initialValues: SOUpdateRequestDto = {
  //codiceInca: undefined,
  denominazione: undefined,
  declaratoria: undefined,
  dataInizioValidita: undefined,
  tipo: undefined,
  dipartimento: undefined,
  email1: undefined,
  email2: undefined,
  pec1: undefined,
  pec2: undefined,
  telefono1: undefined,
  telefono2: undefined,
  fax: undefined,
  indirizzo: undefined,
  citta: undefined,
  idFile: undefined,
  cap: undefined,
  stato: undefined,
  requestType: 'UpdateEntity'
}

const ModificaStruttureOrganizzative = (props: {operations: RuoloUtenteAutenticatoDto}) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetUpdateForm())
    getDetailsFromApi(id!)
  }, [0])

  const strutturaOrganizzativa = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult)
  const updateResult = useAppSelector((state) => state.strutturaOrganizzativa.updateResult)
  const strutturaOrganizzativaResult = useAppSelector((state) => state.strutturaOrganizzativa.detailsStatus)
  const [idFileUpload, setIdFileUpload] = useState<number>()
  const fieldUsed: Array<string> = [DENOMINAZIONE, DATA_INIZIO_VALIDITA, TIPOLOGIA_STRUTTURA, UNITA_ORGANIZZATIVA];
  const hiddenField: Array<string> = [DENOMINAZIONE, DECLARATORIA, EMAIL, EMAIL_2, PEC, PEC_2, TELEFONO, TELEFONO_2, INDIRIZZO, CITTA, CAP, DATA_INIZIO_VALIDITA ];

  const showModificaOperations = () => 
    (props.operations?.elencoFunzionalita?.includes('SO_MOD_DIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_MOD_SEZ')
    || props.operations?.elencoFunzionalita?.includes('SO_MOD_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_DISA_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_DISA_DIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_DISA_SEZ')
    || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODCIFRA')
    || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_ASS_CODBILA')
    || props.operations?.elencoFunzionalita?.includes('SO_INOLTRA')
    || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_DIP')
    || props.operations?.elencoFunzionalita?.includes('SO_ASSOCIA_SEZ')
    || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SERV')
    || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_DIPA')
    || props.operations?.elencoFunzionalita?.includes('SO_DISASSOCIAZIONE_SEZ')   
  );
    

  const handleIdChange = (id: number | undefined) => {
    if (id && id !== -1) {
      updateForm.setFieldValue('idFile', id);
      setIdFileUpload(id)
    } else {
      updateForm.setFieldValue('idFile', undefined);
      setIdFileUpload(undefined)
    }
  }

  const getDetailsFromApi = (id: string) => {
    dispatch(detailsStruttureOrganizzative(id));
  }

  const updateForm: FormikProps<SOUpdateRequestDto> = useFormik({
    initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: values => {
      console.log(updateForm);
      doUpdate();
    }
  });

  useEffect(() => {
    updateForm.setValues({
      codiceInca: strutturaOrganizzativa?.id || '',
      denominazione: strutturaOrganizzativa?.descrizioneBreve || '',
      declaratoria: strutturaOrganizzativa?.descrizione || '',
      dataInizioValidita: strutturaOrganizzativa?.dataInizioValidita || '',
      tipo: strutturaOrganizzativa?.tipo || undefined,
      dipartimento: strutturaOrganizzativa?.codiceDipartimentoRagioneria || undefined,
      email1: strutturaOrganizzativa?.email1 || '',
      email2: strutturaOrganizzativa?.email2 || '',
      pec1: strutturaOrganizzativa?.pec1 || '',
      pec2: strutturaOrganizzativa?.pec2 || '',
      telefono1: strutturaOrganizzativa?.telefono1 || '',
      telefono2: strutturaOrganizzativa?.telefono2 || '',
      fax: strutturaOrganizzativa?.fax || '',
      indirizzo: strutturaOrganizzativa?.indirizzo || '',
      citta: strutturaOrganizzativa?.citta || '',
      cap: strutturaOrganizzativa?.cap || '',
      stato: 'SAVE',
      requestType: 'UpdateEntity'
    });
    
  }, [strutturaOrganizzativa])


  useEffect(() => {
    getDetailsFromApi(updateResult?.id!)
    setIdFileUpload(undefined)
  }, [updateResult])

  const doUpdate = () => {
    const body = {
      idStrutturaOrganizzativa: strutturaOrganizzativa?.id,
      bodyRequest: {...updateForm.values, citta: updateForm.values.citta?.toUpperCase()}
    }
    dispatch(updateStruttureOrganizzative(body))
  }

  const getFieldError = (
    form: FormikProps<SOUpdateRequestDto>,
    fieldName: keyof SOUpdateRequestDto
  ): string => {
    if (form.getFieldMeta(fieldName).touched) {
      return form.errors[fieldName] || "";
    } else return "";
  };


  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} state={{form: true}} title={t("struttura-organizzativa.modifica-so")!}></PageHeader>
      {strutturaOrganizzativaResult === STATUS_FULLFILLED &&
        <div className="postion-relative pb-5">
          <StruttureOrganizzativeDetailsForm fields={fieldUsed} value={strutturaOrganizzativa!} hiddenFields={hiddenField}/>
          <div className="mt-3 form-custom form-inserimento p-4">
            <div className="form-row">
              <Input type='text'
                label='Denominazione'
                placeholder="Inserisci la denominazione"
                id='input-denominazione'
                name="denominazione"
                maxLength={500}
                invalid={!!getFieldError(updateForm, "denominazione")}
                infoText={t(getFieldError(updateForm, "denominazione")) || ""}
                value={updateForm.values.denominazione}
                onChange={updateForm.handleChange}
                wrapperClassName="required col-md-12" />


              <div className="col-md-12 mb-3">
                <h6>{t("struttura-organizzativa.documento-da-allegare")}</h6>
                <DocumentUpload setDocumentId={handleIdChange} documentName="" className="my-3" />
              </div>              

              <TextArea
                label="Declaratoria"
                placeholder="Inserisci la declaratoria"
                id="input-declaratoria"
                wrapperClassName="required col-md-12"
                name="declaratoria"
                maxLength={5000}
                value={updateForm.values.declaratoria}
                onChange={updateForm.handleChange}
                invalid={!!getFieldError(updateForm, "declaratoria")}
                infoText={t(getFieldError(updateForm, "declaratoria")) || ""}
                disabled={idFileUpload === undefined ? true : false} />


              <Input type='email'
                label='E-mail'
                placeholder="Inserisci email"
                id='input-email-1'
                name="email1"
                maxLength={320}
                invalid={!!getFieldError(updateForm, "email1")}
                infoText={t(getFieldError(updateForm, "email1")) || ""}
                value={updateForm.values.email1}
                onChange={updateForm.handleChange}
                wrapperClassName="required col-md-12" />

              <Input type='email'
                label='E-mail secondaria'
                placeholder="Inserisci email"
                id='input-email-2'
                name="email2"
                maxLength={320}
                invalid={!!getFieldError(updateForm, "email2")}
                infoText={t(getFieldError(updateForm, "email2")) || ""}
                value={updateForm.values.email2}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />


              <Input type='email'
                label='PEC'
                placeholder="Inserisci Pec"
                id='input-pec-1'
                name="pec1"
                maxLength={320}
                invalid={!!getFieldError(updateForm, "pec1")}
                infoText={t(getFieldError(updateForm, "pec1")) || ""}
                value={updateForm.values.pec1}
                onChange={updateForm.handleChange}
                wrapperClassName="required col-md-12" />

              <Input type='email'
                label='PEC secondaria'
                placeholder="Inserisci Pec"
                id='input-pec-2'
                name="pec2"
                maxLength={320}
                invalid={!!getFieldError(updateForm, "pec2")}
                infoText={t(getFieldError(updateForm, "pec2")) || ""}
                value={updateForm.values.pec2}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />

              <Input type='tel'
                label='Telefono'
                placeholder="Inserisci Telefono"
                id='input-telefono-1'
                name="telefono1"
                maxLength={20}
                invalid={!!getFieldError(updateForm, "telefono1")}
                infoText={t(getFieldError(updateForm, "telefono1")) || ""}
                value={updateForm.values.telefono1}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              
              <Input type='tel'
                label='Telefono secondario'
                placeholder="Inserisci Telefono"
                id='input-telefono-2'
                name="telefono2"
                maxLength={20}
                invalid={!!getFieldError(updateForm, "telefono2")}
                infoText={t(getFieldError(updateForm, "telefono2")) || ""}
                value={updateForm.values.telefono2}
                onChange={updateForm.handleChange}
                wrapperClassName="col-md-12" />
              
              <Input type='text'
                label='Comune'
                placeholder="Inserisci Comune"
                id='input-citta'
                name="citta"
                maxLength={100}
                invalid={!!getFieldError(updateForm, "citta")}
                infoText={t(getFieldError(updateForm, "citta")) || ""}
                value={updateForm.values.citta}
                onChange={updateForm.handleChange}
                wrapperClassName="required col-md-12" />

              <Input type='text'
                label='Indirizzo'
                placeholder="Inserisci Indirizzo"
                id='input-indirizzo'
                name="indirizzo"
                maxLength={100}
                // Setted the value of 'autocomplete' to a custom value to prevent triggering autocomplete and avoid the browser suggesting a saved address
                autoComplete="disabled"
                invalid={!!getFieldError(updateForm, "indirizzo")}
                infoText={t(getFieldError(updateForm, "indirizzo")) || ""}
                value={updateForm.values.indirizzo}
                onChange={updateForm.handleChange}
                wrapperClassName="required col-md-12" />

              
              <Input
                type="text"
                label="CAP"
                placeholder="Inserisci il CAP"
                id="input-cap"
                name="cap"
                maxLength={5}
                value={updateForm.values.cap}
                onChange={updateForm.handleChange}
                wrapperClassName="required col-md-6"
                invalid={!!getFieldError(updateForm, "cap")}
                infoText={t(getFieldError(updateForm, "cap")) || ""}
              />
            </div>
            {/* <Input
              type="text"
              label="CAP"
              placeholder="Inserisci il CAP"
              id="input-cap"
              name="cap"
              maxLength={5}
              value={updateForm.values.cap}
              onChange={updateForm.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(updateForm, "cap")}
              infoText={t(getFieldError(updateForm, "cap")) || ""}
            /> */}

          </div>
          <div className="d-flex flex-row-reverse">
            <Button outline color='primary' className="m-2" disabled={!showModificaOperations()} onClick={() => { showModificaOperations() && updateForm.submitForm(); }}>{t('salva')}</Button>
            <Button outline color='secondary' className="m-2" onClick={() => navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`, {state:{form: true}})}>{t('annulla')}</Button>
          </div>
        </div>
      }
    </div>
  )
}

export default ModificaStruttureOrganizzative