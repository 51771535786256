import svgError from '../../assets/images/wired-outline-1140-error1.gif'
import { Button } from 'design-react-kit'
import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks';
import { showLoader, showNotifica } from '../../store/loaderSlice';
import { NOTIFICA_STATO } from '../../utility/Notifica';
import logoArpal from '../../assets/images/Arpal_puglia_blue.png';

const NotAuthorized = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(showNotifica({
            titolo: 'SESSIONE SCADUTA',
            stato: 401,
            messaggio: 'Effettuare un nuovo login',
            tipo: NOTIFICA_STATO.error
        }))
    }, [])

    const login = () => {
        dispatch(showLoader())
        window.location.href = process.env.REACT_APP_MS_AU_LOGIN_URL || '';
        return
    }

    return (
        <div className="d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '75vh' }}>
            <img src={logoArpal} alt="logo ARPAL Puglia" className="img-fluid" style={{width:300, padding: "20px"}}/>
            <h1>Anagrafica Unica</h1>
            <img src={svgError} alt="Errore 401" style={{ width: '200px' }} />
            <p style={{ color: 'gray', fontSize: '2rem' }}>
                Sessione scaduta
            </p>
            <Button onClick={()=>{login()}} color='primary' >Login</Button>
        </div>
    )
}

export default NotAuthorized