import TextareaInfoChars from "components/common/textarea-infochars/TextareaInfoChars";
import { Button, Col, Input } from "design-react-kit";
import { FormikProps, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CustomSelect, {
  CustomSelectItem,
} from "../../components/common/custom-select/CustomSelect";
import DocumentUpload, { DocumentUploadRef } from "../../components/common/document-upload/DocumentUpload";
import PageHeader from "../../components/common/page-header/PageHeader";
import PageLoader from "../../components/common/page-loader/PageLoader";
import { HREF_BOZZA_STRUTTURA_ORGANIZZATIVA, HREF_ELENCO_STRUTTURE_ORGANIZZATIVE } from "../../components/layout/sidemenu/sidemenuConstants";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { OptionDtoStatoStrutturaOrganizzativaValoreEnum, SOInsertRequestDto, SOInsertRequestDtoTipoRichiestaEnum } from "../../services/ms-anagrafica-unica";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { detailsStruttureOrganizzative, formSelectPopulateCreate, insertAndInoltraStrutturaOrganizzativa, insertStruttureOrganizzative, resetInputForm, resetSelectSezioni, resetUpdateForm, selectCreateSOSezioni, updateSoBozza } from "../../store/strutturaOrganizzativaSlice";
import { regex } from "utility/constants";


const initialValues: SOInsertRequestDto = {
  denominazione: '',
  declaratoria: '',
  dataInizioValidita: '',
  tipo: undefined,
  dipartimento: undefined,
  sezione: '',
  email1: '',
  email2: '',
  pec1: '',
  pec2: '',
  telefono1: '',
  telefono2: '',
  fax: '',
  indirizzo: '',
  citta: '',
  tipoRichiesta: undefined,
  stato: undefined,
  idFile: undefined,
  cap: ''
  // equiparata: false,
};

const schemaConfirm = z
  .object({
    denominazione: z.string({ required_error: 'required' }).trim().min(1).max(500, 'lenghtdenominazione'),
    declaratoria: z.string({ required_error: 'required' }).trim().min(1).max(5000, 'lenghtdeclaratoria'),
    dataInizioValidita: z.coerce.date({required_error: 'required'}),
    tipo: z.string({ required_error: 'required' }),
    dipartimento: z.string().optional(),
    sezione: z.string().optional(),
    email1: z.string({required_error: 'required'}).regex(new RegExp(regex.email), "emailNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }),
    email2: z.string().regex(new RegExp(regex.email), "emailNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec1: z.string().regex(new RegExp(regex.pec), "pecNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "pecNonCorretta" }),
    pec2: z.string().regex(new RegExp(regex.pec), "pecNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "pecNonCorretta" }).optional(),
    telefono1: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    telefono2: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    // fax: z.string().regex(new RegExp(/^\d+$/), 'numberFax').trim().min(1).max(20, 'lenghtFax').optional(),
    indirizzo: z.string().trim().min(1).max(100, 'lenghtIndirizzo'),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta'),
    idFile: z.number({ required_error: 'required' }),
    cap: z.string({ required_error: 'required' }).trim().min(5, "lengthCap").max(5, "lengthCap")
  })
    // equiparata: z.boolean().optional(),
  .refine(
    (data) => {
      // obbligatorio se tipoStruttura è SEZIONE o SERVIZIO
      // ritorno false se è vuoto e se tipoStruttura è SEZIONE o SERVIZIO
      // ma la validazione scatta solo DOPO che sono ok le validazioni normali ???
      if ((data.tipo === "SEZIONE" || data.tipo === "SERVIZIO") && !data.dipartimento)
        return false;
      return true;
    },
    {
      message: "required",
      path: ["dipartimento"], // path of error
    }
  );

const schemaSave = z
  .object({
    denominazione: z.string().max(500, 'lenghtdenominazione').optional(),
    declaratoria: z.string().max(5000, 'lenghtdeclaratoria').optional(),
    dataInizioValidita: z.coerce.date().optional(),
    tipo: z.string({ required_error: 'required' }),
    dipartimento: z.string().optional(),
    sezione: z.string().optional(),
    email1: z.string().regex(new RegExp(regex.email), "emailNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    email2: z.string().regex(new RegExp(regex.email), "emailNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "emailNonCorretta" }).optional(),
    pec1: z.string().regex(new RegExp(regex.pec), "pecNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "pecNonCorretta" }).optional(),
    pec2: z.string().regex(new RegExp(regex.pec), "pecNonCorretta").trim().min(1).max(320, 'lenghtEmail').email({ message: "pecNonCorretta" }).optional(),
    telefono1: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    telefono2: z.string().regex(new RegExp(/^\d+$/), 'numberTel').trim().min(1).max(20, 'lenghtTelefono').optional(),
    fax: z.string().regex(new RegExp(/^\d+$/), 'numberFax').trim().min(1).max(20, 'lenghtFax').optional(),
    indirizzo: z.string().trim().min(1).max(200, 'lenghtIndirizzo').optional(),
    citta: z.string().trim().min(1).max(100, 'lenghtCitta').optional(),
    
    idFile: z.number().optional(),
    // equiparata: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if ((data.tipo === "SEZIONE" || data.tipo === "SERVIZIO") && !data.dipartimento)
        return false;
      return true;
    },
    {
      message: "required",
      path: ["dipartimento"], // path of error
    }
  );

function NuovaStrutturaOrganizzativa() {

  const { t } = useTranslation();
  const formResult = useAppSelector((state) => state.strutturaOrganizzativa.selectCreateFormResult)
  const formStatus = useAppSelector((state) => state.strutturaOrganizzativa.selectCreateFormStatus)
  const { id } = useParams();
  const [visualizzaDocumento, setVisualizzazioneDocumento] = useState<boolean | null>(null)
  const [button, setButtonSubmit] = useState('')
  const navigate = useNavigate();
  //const validationSchema = button === "BTN_CONFERMA" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave);
  const [tipiStruttura, setTipiStruttura] = useState<CustomSelectItem[]>([]);
  const [dipartimentoOptions, setDipartimentoStruttura] = useState<CustomSelectItem[]>([]);
  const [sezioneOptions, setSezioneStruttura] = useState<CustomSelectItem[]>([]);
  const dispatch = useAppDispatch();
  const ref = useRef<DocumentUploadRef>(null);
  const insertResult = useAppSelector((state) => state.strutturaOrganizzativa.inputResult);
  const dettaglioSoBozza = useAppSelector((state) => state.strutturaOrganizzativa.detailsResult);
  const sezioniCreaStrutturaOrganizzativaResult = useAppSelector ((state)=> state.strutturaOrganizzativa.sezioniCreaStrutturaOrganizzativaResult);
  const [labels, setSOLabels] = useState<{firstLevel?: string , secondLevel?: string}>({firstLevel: 'Area/CPI/Ufficio' , secondLevel: 'Sportello'});
  const handleIdChange = (id: number | undefined) => {
    if (id && id !== -1) {
      formSO.setFieldValue('idFile', id);
    } else {
      formSO.setFieldValue('idFile', undefined);
    }
  }

  useEffect(() => {
    dispatch(formSelectPopulateCreate());
    dispatch(resetUpdateForm())
  }, [])

  useEffect(() => {
    let customSelectArrayTipo: CustomSelectItem[] = [];
    formResult?.tipo?.forEach((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label !== undefined ? value.label : '',
        value: value.valore !== undefined ? value.valore : ''
      };
      customSelectArrayTipo.push(customSelect);
    });
    setTipiStruttura(customSelectArrayTipo)

    let customSelectArrayDipartimento: CustomSelectItem[] = [];
    formResult?.dipartimento?.forEach((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label !== undefined ? value.label : '',
        value: value.valore !== undefined ? value.valore : ''
      };
      customSelectArrayDipartimento.push(customSelect);
    });
    setDipartimentoStruttura(customSelectArrayDipartimento)

  }, [formResult]);

  useEffect(() => {
    if(id !== null && id !== undefined){
      dispatch(detailsStruttureOrganizzative(id));
    }else {
      dispatch(formSelectPopulateCreate());
      dispatch(resetUpdateForm())
    }
  }, [id])


  useEffect(() => {
    if(dettaglioSoBozza && dettaglioSoBozza.stato !== OptionDtoStatoStrutturaOrganizzativaValoreEnum.Compilata)
      navigate(HREF_ELENCO_STRUTTURE_ORGANIZZATIVE);

    let soDipa, soSez;
    handleSelectTipoStruttura(dettaglioSoBozza?.tipo);
    if(dettaglioSoBozza?.strutturaOrganizzativaPadre) {
      const soLiv1 = dettaglioSoBozza?.strutturaOrganizzativaPadre;

      switch (soLiv1.tipo) {
          case 'DIPARTIMENTO':
              soDipa = soLiv1;
              break;
          case 'SEZIONE':
              soSez = soLiv1;
              break
          default:
              break;
      }

      if(soLiv1.strutturaOrganizzativaPadre) {
          const soLiv2 = soLiv1.strutturaOrganizzativaPadre;
          switch (soLiv2.tipo) {
              case 'DIPARTIMENTO':
                  soDipa = soLiv2;
                  break;
              case 'SEZIONE':
                  soSez = soLiv2;
                  break
              default:
                  break;
          }
      }
    }

    formSO.setValues({
      codiceInca: dettaglioSoBozza?.id || '',
      denominazione: dettaglioSoBozza?.descrizioneBreve || '',
      declaratoria: dettaglioSoBozza?.descrizione || '',
      dataInizioValidita: dettaglioSoBozza?.dataInizioValidita || '',
      tipo: dettaglioSoBozza?.tipo || undefined,
      dipartimento: soDipa?.id || undefined,
      sezione: soSez?.id || undefined,
      email1: dettaglioSoBozza?.email1 || '',
      email2: dettaglioSoBozza?.email2 || '',
      pec1: dettaglioSoBozza?.pec1 || '',
      pec2: dettaglioSoBozza?.pec2 || '',
      telefono1: dettaglioSoBozza?.telefono1 || '',
      telefono2: dettaglioSoBozza?.telefono2 || '',
      fax: dettaglioSoBozza?.fax || '',
      indirizzo: dettaglioSoBozza?.indirizzo || '',
      citta: dettaglioSoBozza?.citta || '',
      cap: dettaglioSoBozza?.cap || '',
      idFile: dettaglioSoBozza?.documenti != null && dettaglioSoBozza?.documenti.length > 0
      ? dettaglioSoBozza?.documenti[0].id?.documento?.id
      : undefined 
    }
  )
  }, [dettaglioSoBozza])


  const formSO: FormikProps<SOInsertRequestDto> = useFormik({
    initialValues: initialValues,
    validationSchema: button === "BTN_CONFERMA" ? toFormikValidationSchema(schemaConfirm) : toFormikValidationSchema(schemaSave),
    onSubmit: (values) => {
      doInsert();
    },
  });

  useEffect(() => {
    if (insertResult !== undefined && insertResult !== null) {
      if(button === 'BTN_CONFERMA'){
        navigate(HREF_ELENCO_STRUTTURE_ORGANIZZATIVE);
        return;
      } else
        if(id === null || id === undefined){
          navigate(`${HREF_BOZZA_STRUTTURA_ORGANIZZATIVA}/${insertResult.id}`);
          return;
        }

      handleSelectTipoStruttura(insertResult.tipo);
      
      formSO.setValues({
        codiceInca: insertResult?.id || '',
        denominazione: insertResult?.descrizioneBreve || '',
        declaratoria: insertResult?.descrizione || '',
        dataInizioValidita: insertResult?.dataInizioValidita || '',
        tipo: insertResult?.tipo || undefined,
        dipartimento: formSO.values.dipartimento,
        sezione: formSO.values.sezione|| undefined,
        email1: insertResult?.email1 || '',
        email2: insertResult?.email2 || '',
        pec1: insertResult?.pec1 || '',
        pec2: insertResult?.pec2 || '',
        telefono1: insertResult?.telefono1 || '',
        telefono2: insertResult?.telefono2 || '',
        fax: insertResult?.fax || '',
        indirizzo: insertResult?.indirizzo || '',
        citta: insertResult?.citta || '',
        cap: insertResult?.cap || '',
        idFile:  insertResult?.documenti != null && insertResult?.documenti.length > 0
        ? insertResult?.documenti[0].id?.documento?.id
        : undefined,
      })
    }
  }, [insertResult])




  const doInsert = () => {
    let body = {
      ...formSO.values,
      citta: formSO.values?.citta?.toUpperCase(),
      tipoRichiesta: button === 'BTN_CONFERMA' ? SOInsertRequestDtoTipoRichiestaEnum.Confirm : SOInsertRequestDtoTipoRichiestaEnum.Save,
    }

      if (button === 'BTN_CONFERMA') {
        // CONFERMA
        dispatch(insertAndInoltraStrutturaOrganizzativa(body))
      } else {
        // SALVA BOZZA
        if (id === null || id === undefined) {
          dispatch(insertStruttureOrganizzative(body))
        } else {
          dispatch(updateSoBozza(body))
        }
      }
  };

  const handleSelectChange = (value: any, name: string) => {
    if (value === "DIPARTIMENTO") {
      setSOLabels({ firstLevel: "Area/CPI/Ufficio", secondLevel: "Sportello" });
      formSO.setFieldValue('dipartimento', undefined);
      formSO.setFieldValue('sezione', undefined);
    } else if (value === "SEZIONE") {
      setSOLabels({ firstLevel: "Unità organizzativa", secondLevel: "Area/CPI/Ufficio"});
      formSO.setFieldValue('sezione', undefined);
    } else if (value === '') {
      formSO.setFieldValue('dipartimento', undefined);
      formSO.setFieldValue('sezione', undefined);
    }
    formSO.setFieldValue(name, value);
  };

  const handleSelectTipoStruttura = (tipoSo: any) => {
    if (tipoSo === "DIPARTIMENTO" || tipoSo === "SEZIONE") {
      setVisualizzazioneDocumento(true);
    } else if (tipoSo === "SERVIZIO") {
      setVisualizzazioneDocumento(false);
    } else {
      setVisualizzazioneDocumento(null);
    }
  }

  useEffect(() => {
    if (formSO.values.tipo === undefined) {
      handleSelectTipoStruttura(undefined);
    }
  }, [formSO.values.tipo]);

  useEffect(() => {
    let customSelectArraySezione: CustomSelectItem[] = [];
    sezioniCreaStrutturaOrganizzativaResult?.map((value) => {
      let customSelect: CustomSelectItem = {
        label: value.label !== undefined ? value.label : '',
        value: value.valore !== undefined ? value.valore : ''
      };
      customSelectArraySezione.push(customSelect);
    });
    setSezioneStruttura(customSelectArraySezione)
  }, [sezioniCreaStrutturaOrganizzativaResult])

  const populateSezioneSelect = (e: any, name: string) =>{
    if(e === ""){
        dispatch(resetSelectSezioni())
    }else {
      dispatch(selectCreateSOSezioni(e))
    }
  }

  const getFieldError = (
    form: FormikProps<SOInsertRequestDto>,
    fieldName: keyof SOInsertRequestDto
  ): string => {
    if (formSO.getFieldMeta(fieldName).touched) {
      return formSO.errors[fieldName] || "";
    } else return "";
  };

  const submitConfirm = () => {
    setButtonSubmit('BTN_CONFERMA');
    setTimeout(() => {
      formSO.submitForm();
    }, 0);
  }

  const submitSave = () => {
    setButtonSubmit('BTN_SALVA');
    setTimeout(() => {
      formSO.submitForm();
    }, 0);
  }

  const resetForm = () => {
    ref.current?.resetUploadFile();
    formSO.resetForm();
  }

  return (
    <div>
      <PageHeader showIcon={true} urlBack={HREF_ELENCO_STRUTTURE_ORGANIZZATIVE} title={`${id == null ? 'Nuova' : 'Bozza'} Struttura Organizzativa`}></PageHeader>
      {formStatus === STATUS_FULLFILLED &&
      <>
        <div className="form-custom form-bg form-inserimento border">
          {false && <PageLoader />}


          <div className="form-row">
            <Input
              label="Denominazione"
              type="text"
              placeholder="Inserisci la denominazione"
              id="input-denominazione"
              wrapperClassName="required col-md-12"
              name="denominazione"
              maxLength={500}
              value={formSO.values.denominazione}
              onChange={formSO.handleChange}
              invalid={!!getFieldError(formSO, "denominazione")}
              infoText={t(getFieldError(formSO, "denominazione")) || ""}
            />

            <TextareaInfoChars
              label="Declaratoria"
              placeholder="Inserisci la declaratoria"
              id="input-declaratoria"
              wrapperClassName="required col-md-12"
              name="declaratoria"
              value={formSO.values.declaratoria}
              onChange={formSO.handleChange}
              invalid={!!getFieldError(formSO, "declaratoria")}
              infoText={t(getFieldError(formSO, "declaratoria")) || ""}
              maxLength={5000}
              rows={6}
            />

            <Input
              type="date"
              label="Data inizio validità"
              placeholder="Inserisci la data di inizio Validità"
              id="input-dataInizioValidita"
              name="dataInizioValidita"
              value={formSO.values.dataInizioValidita}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "dataInizioValidita")}
              infoText={t(getFieldError(formSO, "dataInizioValidita")) || ""}
            />

            <Col xl="6">
              <CustomSelect
                label="Tipologia unità organizzativa"
                placeholder="Seleziona tipo struttura"
                options={tipiStruttura}
                name="tipoStruttura"
                invalid={!!getFieldError(formSO, "tipo")}
                infoText={t(getFieldError(formSO, "tipo")) || ""}
                value={formSO.values.tipo}
                onChange={(e) => { handleSelectChange(e, "tipo"); handleSelectTipoStruttura(e) }}
                wrapperClass="required"
                disabled={id !== undefined}
              />
            </Col>

            <Col xl="6">
              <CustomSelect
                label={labels.firstLevel}
                name="dipartimento"
                disabled={
                  (formSO.values.tipo !== "SEZIONE" &&
                  formSO.values.tipo !== "SERVIZIO") || id !== undefined
                }
                options={dipartimentoOptions}
                invalid={!!getFieldError(formSO, "dipartimento")}
                infoText={t(getFieldError(formSO, "dipartimento")) || ""}
                value={formSO.values.dipartimento}
                onChange={(e) => {handleSelectChange(e, "dipartimento"); populateSezioneSelect(e, "dipartimento")}}
              />
            </Col>

            <Col xl="6">
              <CustomSelect
                label={labels.secondLevel}
                name="sezione"
                disabled={formSO.values.tipo !== "SERVIZIO"}
                options={sezioneOptions}
                invalid={!!getFieldError(formSO, "sezione")}
                infoText={t(getFieldError(formSO, "sezione")) || ""}
                value={formSO.values.sezione}
                onChange={(e) => handleSelectChange(e, "sezione")}
              />
            </Col>

            <Input
              type="email"
              label="E-mail"
              placeholder="Inserisci email"
              id="input-email-1"
              name="email1"
              maxLength={320}
              value={formSO.values.email1}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "email1")}
              infoText={t(getFieldError(formSO, "email1")) || ""}
            />

            <Input
              type="email"
              label="E-mail secondaria"
              placeholder="Inserisci email 2"
              id="input-email-2"
              name="email2"
              maxLength={320}
              value={formSO.values.email2}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "email2")}
              infoText={t(getFieldError(formSO, "email2")) || ""}
            />
            
            <Input
              type="email"
              label="PEC"
              placeholder="Inserisci PEC"
              id="input-pec-1"
              name="pec1"
              maxLength={320}
              value={formSO.values.pec1}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "pec1")}
              infoText={t(getFieldError(formSO, "pec1")) || ""}
            />

            <Input
              type="email"
              label="PEC secondaria"
              placeholder="Inserisci PEC"
              id="input-pec-2"
              name="pec2"
              maxLength={320}
              value={formSO.values.pec2}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "pec2")}
              infoText={t(getFieldError(formSO, "pec2")) || ""}
            />
            
            <Input
              type="tel"
              label="Telefono"
              placeholder="Inserisci telefono"
              id="input-telefono-1"
              name="telefono1"
              maxLength={20}
              value={formSO.values.telefono1}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "telefono1")}
              infoText={t(getFieldError(formSO, "telefono1")) || ""}
            />

            <Input
              type="tel"
              label="Telefono secondario"
              placeholder="Inserisci telefono"
              id="input-telefono-2"
              name="telefono2"
              maxLength={20}
              value={formSO.values.telefono2}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "telefono2")}
              infoText={t(getFieldError(formSO, "telefono2")) || ""}
            />
            {/* <Input
              type="tel"
              label="Fax"
              placeholder="Inserisci il fax"
              id="input-fax"
              name="fax"
              value={formSO.values.fax}
              onChange={formSO.handleChange}
              wrapperClassName="col-md-6"
              invalid={!!getFieldError(formSO, "fax")}
              infoText={t(getFieldError(formSO, "fax")) || ""}
            /> */}

            <Input
              type="text"
              label="Comune"
              placeholder="Inserisci comune"
              id="input-citta"
              name="citta"
              value={formSO.values.citta}
              maxLength={100}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "citta")}
              infoText={t(getFieldError(formSO, "citta")) || ""}
            />

            <Input
              type="text"
              label="Indirizzo"
              placeholder="Inserisci indirizzo"
              id="input-indirizzo"
              name="indirizzo"
              // Setted the value of 'autocomplete' to a custom value to prevent triggering autocomplete and avoid the browser suggesting a saved address
              autoComplete="disabled"
              maxLength={100}
              value={formSO.values.indirizzo}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "indirizzo")}
              infoText={t(getFieldError(formSO, "indirizzo")) || ""}
            />
            <Input
              type="text"
              label="CAP"
              placeholder="Inserisci il CAP"
              id="input-cap"
              name="cap"
              maxLength={5}
              autoComplete="disabled"
              value={formSO.values.cap}
              onChange={formSO.handleChange}
              wrapperClassName="required col-md-6"
              invalid={!!getFieldError(formSO, "cap")}
              infoText={t(getFieldError(formSO, "cap")) || ""}
            />
       

            {/* <FormGroup check className="mb-2">
              <Input id='strutturaEquiparata' name="equiparata" onChange={formSO.handleChange} checked={formSO.values.equiparata} type='checkbox' />
              <Label for='strutturaEquiparata' check>
                Struttura equiparata
              </Label>
            </FormGroup> */}

          </div>
          {visualizzaDocumento !== null && (
            <DocumentUpload ref={ref} 
              idDocumento={formSO.values.idFile} 
              invalid={!!formSO.errors.idFile} 
              infoText={t(getFieldError(formSO, "idFile")) || ""} 
              setDocumentId={handleIdChange} 
              documentName={t(`struttura-organizzativa.${visualizzaDocumento ? 'doc-provvedimento-giunta' : 'doc-determina-dirigenziale'}`)} 
              className="my-3 required" />
          )}
          
        </div>
        <div className="d-flex flex-row-reverse mt-2">
          <Button color='primary' className="mt-2" onClick={() => { submitConfirm(); }}> {t('conferma')}</Button>
          <Button outline color='primary' className="mt-2 mr-2" onClick={() => { submitSave(); }}>{t('salva_bozza')}</Button>
          <Button color='secondary' className="mt-2 mr-2"  onClick={() => {resetForm() }} >{t('reset')} </Button>
          <Button color='secondary' outline className="mt-2 mr-2" onClick={() => { navigate(`${HREF_ELENCO_STRUTTURE_ORGANIZZATIVE}`); dispatch(resetInputForm()) }} >{t('annulla')} </Button>
        </div>
        </>
      }
    </div>
  );
}

export default NuovaStrutturaOrganizzativa;
